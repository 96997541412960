/* eslint-disable react/display-name */
import React, { Fragment, forwardRef, useEffect, useRef } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { Trans, withTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DefaultColumnFilter } from './filters';
import Pagination from './Pagination';

const RowCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  activePage,
  totalPages,
  setPage,
  pageSize,
  isLoading = false,
  selectedRows = [],
  setSelectedRows,
  hiddenColumns = [],
  count,
  t,
  disabled = false,
}) => {

  const options = {
    columns,
    data,
    defaultColumn: { Filter: DefaultColumnFilter },
    manualPagination: true,
    pageCount: totalPages,
    getRowId: (value, index) => {
      if (value?.id) return value.id;
      if (value?.external_id) return value.external_id;

      const randomChars = Math.random().toString(36).substring(2, 8); 
      return `${randomChars}-${index}`;
    },
    state: { selectedRowIds: selectedRows },
    initialState: {
      hiddenColumns,
      selectedRowIds: selectedRows,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { selectedRowIds },

  } = useTable(options, usePagination, useRowSelect, (hooks) => {

    if (setSelectedRows) {

      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps, ...rest }) => {
            return (
              <RowCheckbox
                onClick={() => {
                  const { checked } = getToggleAllRowsSelectedProps();

                  const values = Object.keys(rest?.data)?.reduce((acc, k) => {
                    acc[rest.data[k]?.external_id] = !checked;
                    return acc;
                  }, {});

                  setSelectedRows({ ...selectedRows, ...values });
                }}
                {...getToggleAllRowsSelectedProps()}
                disabled={disabled}
              />
            );
          },

          Cell: ({ row }) => (
            <RowCheckbox
              onClick={() => {
                const items = selectedRowIds;
                const exists = items?.[row.id];
                setSelectedRows({ ...items, [row.id]: !exists });
              }}
              {...row.getToggleRowSelectedProps()}
              disabled={disabled}
            />
          ),
        },
        ...columns,
      ]);
    }
  });

  return (
    <Fragment>
      <div className={`mt-3 ${divClass}`}>
        <Table
          hover
          {...getTableProps()}
          className={tableClass}
          style={{ marginBottom: 0 }}
        >
          <thead className={theadClass}>
            {headerGroups.map((headerGroup, key) => (
              <tr
                className={trClass}
                key={key}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={classnames(thClass, {
                      'align-middle': true,
                      'w-25px': column?.isCheckbox ? '25px' : false,
                    })}
                  >
                    <Trans>{column.render('Header')}</Trans>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.length ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={headerGroups[0]?.headers?.length}
                  className="bg-light  p-4 text-center"
                >
                  {t('table-content-empty')}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {page && (
        <Pagination
          currentPage={Number(activePage || 1)}
          totalCount={count}
          pageSize={pageSize}
          onPageChange={setPage}
          disabled={isLoading}
        />
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(TableContainer);
