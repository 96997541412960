export const removeBomFromBinaryFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      let arrayBuffer = reader.result;
      const uint8Array = new Uint8Array(arrayBuffer);

      if (uint8Array[0] === 0xEF && uint8Array[1] === 0xBB && uint8Array[2] === 0xBF) {
        arrayBuffer = arrayBuffer.slice(3);
      }

      const cleanedFile = new File([arrayBuffer], file.name, {
        type: file.type || 'text/csv',
      });

      resolve(cleanedFile);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};
