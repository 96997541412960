import React from 'react';
import { Navigate } from 'react-router-dom';

import ManagerPanel from '../pages/ManagerPanel';
import OnePageReport from '../pages/OnePageReport';
import Reports from '../pages/Reports';
import CheckingAccount from '../pages/Reports/components/CheckingAccount';

//pages
import Starter from '../pages/Pages/Starter/Starter';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';

//login
import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import RecoverPasswordPage from '../pages/Authentication/RecoverPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import OrdersManagement from '../pages/OrdersManagement';
import DigitalWallets from '../pages/DigitalWallets';
import DigitalWalletDetail from '../pages/DigitalWallets/Details';
import AccountStrategy from '../pages/Strategy/account';
import CatalogStrategy from '../pages/Strategy/catalog';
import CatalogStrategyEdit from '../pages/Strategy/NewStrategy';
import NewStrategySelect from '../pages/Strategy/NewStrategySelect';
import NewStrategy from '../pages/Strategy/NewStrategy';
import NewOrder from '../pages/ManualOrder/NewOrder';
import Incentive from '../pages/Incentive/manual';
import IncentiveDetail from '../pages/Incentive/manual/Detail';
import SmartIncentives from '../pages/Incentive/smart';
import Catalog from '../pages/Catalog';
import CatalogBlocked from '../pages/Catalog/Blocked';
import User from '../pages/User';
import Permission from '../pages/Permission';
import TransactionalRuler from '../pages/Communication/TransactionalRuler/Transactional';
import TransactionalTemplates from '../pages/Communication/Templates';
import LeadCapture from '../pages/LeadCapture';
import LeadCaptureSettings from '../pages/LeadCapture/settings';
import OrderManagementDetails from '../pages/OrdersManagement/details';
import MyWalletSettings from '../pages/MyWallet';
import Integrations from '../pages/Integrations';
import Stores from '../pages/Stores';
import GeneralSettings from '../pages/GeneralSettings';
import ShippingHistory from '../pages/Communication/ShippingHistory';
import SalesSummary from '../pages/Results/SalesSummary';
import CommunicationSummary from '../pages/Results/CommunicationSummary';
import CreditsSummary from '../pages/Results/CreditsSummary';

const authProtectedRoutes = [
  { path: "/manager-panel/orders", component: <ManagerPanel /> },
  { path: "/resultados/vendas", component: <SalesSummary /> },
  { path: "/resultados/comunicacoes", component: <CommunicationSummary /> },
  { path: "/resultados/creditos", component: <CreditsSummary /> },
  { path: '/dashboard', component: <OnePageReport /> },
  { path: "/dashboard/reports", component: <Reports /> },
  { path: "/dashboard/conta-corrente", component: <CheckingAccount /> },
  { path: '/index', component: <OnePageReport /> },
  { path: "/vendas", component: <OrdersManagement /> },
  { path: "/vendas/novo", component: <NewOrder /> },
  { path: "/vendas/:orderId", component: <OrderManagementDetails /> },
  { path: "/carteiras", component: <DigitalWallets /> },
  { path: "/carteiras/detalhes/:document", component: <DigitalWalletDetail /> },
  { path: "/estrategias/conta", component: <AccountStrategy /> },
  { path: "/estrategias/catalogo", component: <CatalogStrategy /> },
  { path: "/estrategias/detalhes/:slug", component: <CatalogStrategyEdit /> },
  { path: "/estrategias/novo", component: <NewStrategySelect /> },
  { path: "/estrategias/novo/:type", component: <NewStrategy /> },
  { path: "/incentivos", component: <Incentive /> },
  { path: "/incentivos/:groupId", component: <IncentiveDetail /> },
  { path: "/incentivos-inteligentes", component: <SmartIncentives /> },
  { path: "/catalogo", component: <Catalog /> },
  { path: "/catalogo/bloqueados", component: <CatalogBlocked /> },
  { path: "/conta/usuarios", component: <User /> },
  { path: "/conta/permissoes", component: <Permission /> },

  { path: '/conta/lojas', component: <Stores /> },
  { path: '/conta/configuracoes-gerais', component: <GeneralSettings /> },
  { path: '/integracoes', component: <Integrations /> },
  { path: '/comunicacao/historico', component: <ShippingHistory /> },
  { path: '/comunicacao/transacional', component: <TransactionalRuler /> },
  { path: '/comunicacao/templates', component: <TransactionalTemplates /> },
  { path: '/captura-de-leads', component: <LeadCapture /> },
  {
    path: '/captura-de-leads/configuracao',
    component: <LeadCaptureSettings />,
  },
  { path: '/minha-carteira', component: <MyWalletSettings /> },

  //Pages
  { path: '/pages-starter', component: <Starter /> },

  // this route should be at the end of all other routes

  {
    path: '/',
    exact: true,
    component: <Navigate to="/results/sales-summary" />,
  },
  { path: '*', component: <Navigate to="/results/sales-summary" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/recuperar-senha', component: <ForgetPasswordPage /> },
  { path: '/recuperar-senha/confirmar', component: <RecoverPasswordPage /> },
  { path: '/register', component: <Register /> },

  { path: '/pages-maintenance', component: <Maintenance /> },
  { path: '/pages-coming-soon', component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
